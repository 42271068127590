import React, { FC, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import {
  CreditCardProcessor,
  PaymentMethodType,
  Trip,
  useCreateSquareCardMutation,
  useCreateSquareCustomerMutation,
  useExecuteCreatePaymentMethodMutation,
  useGetMerchantQuery,
  useSearchSquareCustomersQuery,
  TripClientRecord,
  ClientRole,
} from 'src/.gen/graphql';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { useSnackbar } from 'notistack';
import { DeepPartial } from 'react-hook-form';
import RegisteredSquarePaymentOptions from './RegisteredSquarePaymentOption';

type SquarePaymentMethodForTripProps = {
  trip: Partial<Trip>;
  tripClients?: DeepPartial<TripClientRecord>[];
  setCurrentMethod: (
    methodId: string,
    methodType: PaymentMethodType,
    methodDescription: string | undefined,
  ) => void;
  editing: boolean;
  setInProgress: (inProgress: boolean) => void;
  setupInProgress: boolean;
};

const SquarePaymentMethodForTrip: FC<SquarePaymentMethodForTripProps> = ({
  trip,
  tripClients,
  setCurrentMethod,
  editing = false,
  setInProgress,
  setupInProgress = false,
}) => {
  const PASSENGER = 'passenger';
  const BOOKER = 'booker';
  const DONT_SAVE = 'none';
  const [checkbox, setCheckbox] = useState<string>(PASSENGER);
  const { enqueueSnackbar } = useSnackbar();

  const beginSetup = async () => {
    setInProgress(true);
  };

  const passengerClient = tripClients?.find(
    (tripClient) => !!tripClient?.isPrimary,
  );
  const bookerClient = tripClients?.find(
    (tripClient) => tripClient?.role === ClientRole.Booker,
  );

  const bookingClientId = bookerClient?.clientId;
  const passengerClientId = passengerClient?.clientId;

  const { data: merchant } = useGetMerchantQuery();

  const { data: squarePassengerResponse } = useSearchSquareCustomersQuery({
    variables: { referenceId: passengerClientId },
  });

  const { data: squareBookerResponse } = useSearchSquareCustomersQuery({
    variables: { referenceId: bookingClientId },
  });

  const [createSquareCustomer] = useCreateSquareCustomerMutation({
    refetchQueries: ['SearchSquareCustomers'],
  });

  const [createSquareCard] = useCreateSquareCardMutation();

  const [createPaymentMethod] = useExecuteCreatePaymentMethodMutation({
    refetchQueries: ['GetTripById', 'getPaymentMethodByTrip'],
  });

  const createCard = async (squareCardToken: string) => {
    let clientId = null;
    let squareCustomer = null;
    let squareCustomerId = passengerClient?.clientId;

    if (checkbox === PASSENGER) {
      clientId = passengerClient?.clientId;
      squareCustomer = squarePassengerResponse?.square?.searchSquareCustomers
        ? squarePassengerResponse.square.searchSquareCustomers[0]
        : null;
    }
    if (checkbox === BOOKER) {
      clientId = bookerClient?.clientId;
      squareCustomerId = bookerClient?.clientId;
      squareCustomer = squareBookerResponse?.square?.searchSquareCustomers
        ? squareBookerResponse.square.searchSquareCustomers[0]
        : null;
    }

    if (checkbox === DONT_SAVE) {
      clientId = null;
      squareCustomer = squarePassengerResponse?.square?.searchSquareCustomers
        ? squarePassengerResponse.square.searchSquareCustomers[0]
        : null;
    }

    let customerId = squareCustomer?.id;
    if (!squareCustomer) {
      const response = await createSquareCustomer({
        variables: { customerContactId: squareCustomerId },
      });
      customerId = response.data.createSquareCustomer.squareId;
    }
    if (customerId) {
      const squareCard = await createSquareCard({
        variables: {
          input: {
            sourceId: squareCardToken,
            card: { customerId },
          },
        },
      });

      if (!squareCard.data.createSquareCard.squareId) {
        enqueueSnackbar('Error: Invalid Card Information', {
          variant: 'error',
          preventDuplicate: true,
        });
      } else {
        const paymentCreated = await createPaymentMethod({
          variables: {
            tripId: trip.id,
            externalPaymentMethodId: squareCard.data.createSquareCard.squareId,
            customerContactId: clientId,
            source: CreditCardProcessor.Square,
          },
          refetchQueries: ['GetTripById', 'getPaymentMethodByTrip'],
        });

        if (paymentCreated.data.executeCreatePaymentMethod.succeeded) {
          setCurrentMethod(
            paymentCreated.data.executeCreatePaymentMethod.trip.paymentMethodId,
            PaymentMethodType.CreditCard,
            paymentCreated.data.executeCreatePaymentMethod.trip.paymentMethod
              .description,
          );
          enqueueSnackbar('Success! Your payment method has been Created.', {
            variant: 'success',
            preventDuplicate: true,
          });
          setInProgress(false);
        } else {
          enqueueSnackbar('Error: Failed to Create Payment Method', {
            variant: 'error',
            preventDuplicate: true,
          });
        }
      }
    } else {
      enqueueSnackbar('Error: Invalid Card Information', {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return trip?.id &&
    merchant &&
    squarePassengerResponse &&
    (squareBookerResponse || !bookingClientId) ? (
    <React.Fragment>
      {setupInProgress ? (
        <React.Fragment>
          <PaymentForm
            applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
            cardTokenizeResponseReceived={async (token) => {
              await createCard(token.token);
            }}
            locationId={merchant.square.getSquareMerchant.mainLocationId || ''}
          >
            <CreditCard>Submit</CreditCard>
          </PaymentForm>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={checkbox}
              onChange={(e) => {
                setCheckbox(e.target.value);
              }}
            >
              <FormControlLabel
                control={<Radio />}
                value={PASSENGER}
                label="Save this card to passenger profile for future payments"
              />
              {!trip?.wasBookedByPassenger && (
                <FormControlLabel
                  value={BOOKER}
                  control={<Radio />}
                  label="Save this card to booker profile for future payments"
                />
              )}
              <FormControlLabel
                control={<Radio />}
                value={DONT_SAVE}
                label="Don't save this card for future payments"
              />
            </RadioGroup>
          </FormControl>
        </React.Fragment>
      ) : (
        <RegisteredSquarePaymentOptions
          beginSetup={beginSetup}
          trip={trip}
          setCurrentMethod={setCurrentMethod}
          editing={editing}
        />
      )}
    </React.Fragment>
  ) : (
    <Typography variant="h5">Loading</Typography>
  );
};

export default SquarePaymentMethodForTrip;
