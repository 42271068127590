import { Box, CardContent, Grid, Theme, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import {
  Trip,
  TripWaypoint,
  useGetTripByIdLazyQuery,
  useSensorReadingsByTripActualRouteLazyQuery,
  MonitoringSummary,
  TripCompletionStatus,
  useAccountMonitoringSummaryLazyQuery,
  TripProviderSource,
  TripWaypointRole,
} from 'src/.gen/graphql';
import MapWaypoint from 'src/shared/components/Map/MapWaypoint';
import {
  getMarkerDetails,
  getRouteCoordinates,
  orderWaypoints,
} from 'src/shared/utils/mapUtils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tripDistancesAndTimeState } from 'src/trips/atoms/tripFormAtom/tripDistancesAndTime';
import { useLocation } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { getCompletionStatuses } from 'src/shared/utils/tripStatusUtils';
import { userAtom } from 'src/authentication/atoms/AuthState';
import useBreakpoints from 'src/shared/hooks/useBreakpoints';
import { getPreferredDriverWarning } from 'src/trips/utils/preferredDriverWarning';
import LoadingTripMapTile from './LoadingTripMapTile';
import TripWaypointsResume from './TripWaypointsResume';
import TripMonitoringMap from '../TripMonitoring/TripMonitoringMap';
import MapTileCardHeader from './MapTileCardHeader';
import WaypointsTimeAndMiles from './WaypointsTimeAndMiles';
import CardActionsComponent from '../TripPassengerCard/CardActions';
import AcceptOrClaimSharedTripBtn from '../AcceptOrClaimSharedTripBtn';
import type {
  SavoyaTripStop,
  SavoyaAirport,
  SavoyaFlight,
  SavoyaVendorAssignment,
  SavoyaDriver,
} from '../../types/SavoyaTripsTypes';

const useStyles = makeStyles((theme: Theme) => ({
  mapContainer: {
    height: '20rem',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      marginTop: '1rem',
    },
  },
}));

type TripMapTileProps = TripMapTileFetchProps | TripMapTileWithTripProps;

interface TripMapTileFetchProps {
  type: 'Fetch';
  tripId: string;
  savoyaReservationAssignment?: SavoyaVendorAssignment;
  savoyaReservationStops?: SavoyaTripStop[];
  savoyaPreferredDrivers?: SavoyaDriver[];
  isAvailableTrip?: boolean;
  savoyaQuotedTime?: number;
  showHeader?: boolean;
  showActions?: boolean;
  savoya?: boolean;
}

interface TripMapTileWithTripProps {
  type: 'WithTrip';
  trip: Partial<Trip>;
  savoyaReservationAssignment?: SavoyaVendorAssignment;
  savoyaReservationStops?: SavoyaTripStop[];
  savoyaPreferredDrivers?: SavoyaDriver[];
  isAvailableTrip?: boolean;
  savoyaQuotedTime?: number;
  showHeader?: boolean;
  showActions?: boolean;
  savoya?: boolean;
}

type ExtendedStopInfo = {
  note?: string;
  savoyaFlightInfo?: {
    airport?: SavoyaAirport;
    flight?: SavoyaFlight;
  };
  stopNumber?: number;
};
export type ExtendedTripWaypoint = Partial<TripWaypoint & ExtendedStopInfo>;

export const mapSavoyaStopToWaypoint = (
  stops: Partial<SavoyaTripStop>[],
  waypoint: ExtendedTripWaypoint,
): Partial<TripWaypoint & ExtendedStopInfo> => {
  if (stops?.length > 0 || waypoint?.savoyaStop) {
    const waypointOrder = waypoint?.order;
    const locationName = waypoint?.location?.name?.toLowerCase();
    const locationPostalCode = waypoint?.location?.postalCode?.toLowerCase();
    const savoyaStop: SavoyaTripStop =
      (stops || []).find((stop) => {
        if (!locationName && waypointOrder === stop?.stopNumber) {
          return true;
        }
        const stopName =
          stop?.address?.toLowerCase() ||
          stop?.airport?.displayName?.toLowerCase() ||
          stop?.airport?.name?.toLowerCase();
        const stopPostalCode = stop?.postalCode?.toLowerCase();
        if (stop?.airport?.iataCode && stop?.flight?.fbo?.name) {
          return (
            waypoint?.fbo?.iata_code?.toLowerCase() ===
              stop?.airport?.iataCode?.toLowerCase() &&
            waypoint?.fbo?.fbo_name?.toLowerCase() ===
              stop?.flight?.fbo?.name?.toLowerCase()
          );
        }

        if (stop?.airport?.id) {
          return stopName === locationName;
        }

        if (stop?.asDirected) {
          return (
            stop?.displayName === locationName &&
            waypointOrder === stop?.stopNumber
          );
        }

        return (
          stopName === locationName &&
          stopPostalCode === locationPostalCode &&
          waypointOrder === stop?.stopNumber
        );
      }) || waypoint?.savoyaStop;

    if (savoyaStop?.id) {
      let modifiedWaypoint = {
        ...waypoint,
        estimatedDurationInMinutes: savoyaStop?.travelTime,
        note: waypoint?.note || savoyaStop?.note,
        savoyaFlightInfo: {
          airport: savoyaStop?.airport,
          flight: savoyaStop?.flight,
        },
        stopNumber: savoyaStop?.stopNumber,
      };
      // NOTE: this is a temporary solution to show the as directed
      // savoya stop details for old trips
      if (savoyaStop?.asDirected) {
        modifiedWaypoint = {
          ...modifiedWaypoint,
          location: {
            ...modifiedWaypoint?.location,
            name: savoyaStop?.name,
            addressLine1: '',
            city: savoyaStop?.city?.displayName,
            state: '',
            postalCode: '',
            coordinates: {
              type: 'Point',
              coordinates: [
                savoyaStop?.city?.longitude,
                savoyaStop?.city?.latitude,
              ],
            },
          },
        };
      }
      return modifiedWaypoint;
    }
    return waypoint;
  }
  return waypoint;
};

const TripMapTile: React.FC<TripMapTileProps> = (props) => {
  const {
    showHeader,
    showActions,
    savoya = false,
    isAvailableTrip = false,
    savoyaQuotedTime,
    savoyaReservationAssignment,
    savoyaReservationStops,
    savoyaPreferredDrivers,
  } = props;
  const classes = useStyles();
  const { xs, sm, md } = useBreakpoints();
  const location = useLocation();
  const [fetchTripById, { loading, data }] = useGetTripByIdLazyQuery();
  const [getTripSensorReadings, { data: rawSensorReadings }] =
    useSensorReadingsByTripActualRouteLazyQuery();

  const [trip, setTrip] = useState<Partial<Trip> | undefined>();
  const [waypoints, setWaypoints] = useState<ExtendedTripWaypoint[]>([]);

  const [sensorReadings, setSensorReadings] = useState([]);
  const setDistancesAndTime = useSetRecoilState(tripDistancesAndTimeState);
  const { accountId } = useRecoilValue(userAtom);
  const isSmallResolution = xs || sm || md;

  const [
    getSummary,
    {
      data: realTimeData,
      loading: realTimeLoading,
      called,
      error,
      startPolling,
      stopPolling,
    },
  ] = useAccountMonitoringSummaryLazyQuery();

  const savoyaStops = useMemo(() => {
    // NOTE: using .map to trigger dependencies array update
    if (savoyaReservationStops?.length > 0) {
      return savoyaReservationStops?.map((stop) => stop);
    }
    return (trip?.waypoints || []).reduce(
      (stops: SavoyaTripStop[], waypoint) => {
        if (waypoint?.savoyaStop) {
          return [...stops, waypoint?.savoyaStop as SavoyaTripStop];
        }
        return stops;
      },
      [],
    );
  }, [savoyaReservationStops]);

  useEffect(() => {
    if (savoya && trip?.waypoints?.length > 0) {
      const modifiedWaypoints = trip?.waypoints?.map((waypoint) => {
        const modifiedWaypoint = mapSavoyaStopToWaypoint(
          savoyaStops,
          waypoint as ExtendedTripWaypoint,
        );
        return modifiedWaypoint;
      });
      setWaypoints(modifiedWaypoints);
    }
  }, [savoya, trip, savoyaStops]);

  useEffect(() => {
    if (called && !error && !realTimeLoading && trip?.id) {
      startPolling(60000);
    }
    if (
      realTimeData &&
      !inProgressStatuses.includes(
        realTimeData.trips.accountMonitoringSummary.status,
      )
    ) {
      // eslint-disable-next-line no-unused-expressions
      !!stopPolling && stopPolling();
    }
    return () => {
      // eslint-disable-next-line no-unused-expressions
      !!stopPolling && stopPolling();
    };
  }, [realTimeLoading, called, error, trip, realTimeData]);

  useEffect(() => {
    if (props.type === 'Fetch') {
      fetchTripById({
        variables: { tripId: props.tripId },
      });
    } else {
      setTrip(props.trip);
    }
  }, [props]);

  useEffect(() => {
    if (data) {
      setTrip(data?.trips?.byId as Trip);
    }
  }, [data]);

  useEffect(() => {
    if (trip?.id) {
      if (inProgressStatuses.includes(trip.status)) {
        if (
          accountId === trip?.accountId &&
          trip?.providerSource !== TripProviderSource.Savoya
        ) {
          getSummary({ variables: { tripId: trip?.id } });
        } else if (
          trip?.isShared &&
          trip?.providerSource === TripProviderSource.Savoya &&
          trip?.sharedTrip?.vendorExternalId === accountId
        ) {
          getSummary({ variables: { tripId: trip?.id } });
        }
      }
      getTripSensorReadings({
        variables: {
          tripId: trip?.id,
        },
      });
    }
  }, [trip]);

  useEffect(() => {
    if (rawSensorReadings) {
      const items =
        rawSensorReadings?.vehicleSensorReadings?.byTripActualRoute?.items
          .filter(
            (item) =>
              item.coordinates.coordinates[0] &&
              item.coordinates.coordinates[1],
          )
          .map((item) => [
            item.coordinates.coordinates[0],
            item.coordinates.coordinates[1],
          ]);

      if (items) {
        setSensorReadings(items);
      }
    }
  }, [rawSensorReadings]);

  const inProgressStatuses = getCompletionStatuses(
    TripCompletionStatus.InProcess,
  );

  return (
    <CardContent data-testid="trip_map_tile">
      {showHeader ? (
        <MapTileCardHeader
          trip={trip}
          savoyaReservationAssignment={savoyaReservationAssignment}
          isAvailableTrip={isAvailableTrip}
          savoyaQuotedTime={savoyaQuotedTime}
          loading={loading}
        />
      ) : null}
      {!loading && trip && (
        <Grid container spacing={2}>
          <Grid item container spacing={1}>
            <Grid
              item
              container
              xs={12}
              md={
                ['/trips/list', '/shared-trips/list'].includes(
                  location?.pathname,
                )
                  ? 12
                  : 7
              }
              justify="space-between"
            >
              <Grid item xs={12}>
                <TripWaypointsResume
                  isSavoyaView={savoya}
                  waypoints={orderWaypoints(waypoints)}
                  pickupTime={new Date(trip?.pickupTime)}
                  pickupWaypoint={waypoints?.find(
                    (wp) => wp.role === TripWaypointRole.Pickup,
                  )}
                  dropoffWaypoint={waypoints?.find(
                    (wp) => wp.role === TripWaypointRole.Dropoff,
                  )}
                  segments={trip?.segments}
                  overrideDropoffDetails={trip?.overrideDropoffWaypointDetails}
                  hideTimeAndMiles
                  summarizeStops
                />
              </Grid>
              {showActions && !isSmallResolution && !isAvailableTrip ? (
                <Grid item xs={12} style={{ marginTop: '3rem' }}>
                  <CardActionsComponent
                    trip={trip}
                    loading={loading}
                    showEditButton
                    showViewButton
                    paymentButton
                    savoyaReservationAssignment={savoyaReservationAssignment}
                    isAvailableTrip={isAvailableTrip}
                  />
                </Grid>
              ) : null}
              {savoyaPreferredDrivers?.length ? (
                <Grid item style={{ marginTop: '2rem' }}>
                  <Alert severity="error">
                    <Typography color="error">
                      {getPreferredDriverWarning(savoyaPreferredDrivers)}
                    </Typography>
                  </Alert>
                </Grid>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={
                ['/trips/list', '/shared-trips/list'].includes(
                  location?.pathname,
                )
                  ? 12
                  : 5
              }
            >
              <Box className={classes.mapContainer}>
                {!inProgressStatuses.includes(trip.status) ? (
                  <Fragment>
                    <MapWaypoint
                      route="Trip details"
                      calcRoute
                      sensorRouteCoordinates={sensorReadings ?? []}
                      routeCoordinates={getRouteCoordinates(trip?.waypoints)}
                      getRouteTimesAndDistances={(distancesAndTime) => {
                        setDistancesAndTime(distancesAndTime);
                      }}
                      markerDetails={trip ? getMarkerDetails(trip) : undefined}
                    />
                    <WaypointsTimeAndMiles
                      isSavoyaView={savoya}
                      dropoffWaypoint={trip?.dropoffWaypoint}
                      pickupTime={trip?.pickupTime}
                      pickupWaypoint={trip?.pickupWaypoint}
                      segments={trip?.segments}
                      overrideDropoffDetails
                    />
                  </Fragment>
                ) : null}
                {inProgressStatuses.includes(trip.status) &&
                  (realTimeLoading ? (
                    <Skeleton height="100%" width="100%" />
                  ) : (
                    <TripMonitoringMap
                      monitoringSummary={
                        realTimeData?.trips
                          ?.accountMonitoringSummary as MonitoringSummary
                      }
                    />
                  ))}
              </Box>
            </Grid>
            {showActions && isSmallResolution && !isAvailableTrip ? (
              <Grid
                item
                xs={12}
                style={{ marginTop: '2rem', marginBottom: '-1rem' }}
              >
                <CardActionsComponent
                  trip={trip}
                  loading={loading}
                  showEditButton
                  showViewButton
                  paymentButton
                  savoyaReservationAssignment={savoyaReservationAssignment}
                  isAvailableTrip={isAvailableTrip}
                />
              </Grid>
            ) : null}
            {showActions && isAvailableTrip ? (
              <Grid
                item
                xs={12}
                style={{ marginTop: '2rem', marginBottom: '-1rem' }}
              >
                <AcceptOrClaimSharedTripBtn trip={trip} action="claim" />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      )}
      {loading && <LoadingTripMapTile />}
    </CardContent>
  );
};

export default TripMapTile;
