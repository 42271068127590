import { fetchCalculateHourlyQuote } from 'src/trips/api/SavoyaTripQueries';
import type {
  SavoyaReservation,
  SavoyaIncidentals,
  SavoyaReservationTravelTime,
  SavoyaVendorAssignment,
  CloseOutDate,
} from 'src/trips/types/SavoyaTripsTypes';
import { timeUtils } from 'src/shared/utils/timeUtils';
import moment from 'moment';
import { CloseoutFromValues, SavoyaCostType } from '../SavoyaPaymentDetails';

export type TripSummaryType = {
  outDateAdjustment?: CloseOutDate;
  onboardDateAdjustment?: CloseOutDate;
  dropoffDateAdjustment?: CloseOutDate;
  inDateAdjustment?: CloseOutDate;
};

export const getTripSummary = (
  vendorAssignemnt: SavoyaVendorAssignment,
): TripSummaryType => {
  if (!vendorAssignemnt) {
    return {};
  }
  const { reservation, defaultGarageOutTime, defaultGarageInDuration } =
    vendorAssignemnt;
  const garageOutProvided = !!reservation?.outDateAdjustment;

  let outDateAdjustment = reservation?.outDateAdjustment || {
    reportedDate: null,
  };

  const onboardDateAdjustment = reservation?.onboardDateAdjustment || {
    reportedDate: reservation?.pickupDate,
  };

  let dropoffDateAdjustment = reservation?.dropoffDateAdjustment || {
    reportedDate: null,
  };

  let inDateAdjustment = reservation?.inDateAdjustment || {
    reportedDate: null,
  };

  if (garageOutProvided && !defaultGarageOutTime) {
    outDateAdjustment = reservation?.outDateAdjustment;
  } else if (reservation?.vehicleType?.greeter) {
    const pickupDate = reservation?.pickupDate;
    outDateAdjustment = { reportedDate: pickupDate };
    if (reservation.noShow && !garageOutProvided) {
      dropoffDateAdjustment = { reportedDate: pickupDate };
      inDateAdjustment = { reportedDate: pickupDate };
    }
  } else {
    const defaultOutTime = timeUtils.offset(
      reservation?.pickupDate,
      -30,
      'minutes',
    );
    outDateAdjustment = { reportedDate: defaultOutTime };
  }

  if (dropoffDateAdjustment?.reportedDate && defaultGarageInDuration) {
    const defaultInTime = timeUtils.offset(
      dropoffDateAdjustment?.reportedDate,
      30,
      'minutes',
    );
    inDateAdjustment = { reportedDate: defaultInTime };
  }

  return {
    dropoffDateAdjustment,
    inDateAdjustment,
    outDateAdjustment,
    onboardDateAdjustment,
  };
};

export const calculateReportedTripTime = (
  values: CloseoutFromValues,
  travelTimeRules: SavoyaReservationTravelTime,
  pickupDate: string,
): number => {
  const inDate = travelTimeRules?.omitDropoffTravelTime
    ? values?.dropoffDateAdjustment
    : values?.inDateAdjustment;
  const outDate = travelTimeRules?.omitPickupTravelTime
    ? pickupDate
    : values?.outDateAdjustment;

  if (inDate && outDate) {
    return timeUtils.durationBetween(outDate, inDate);
  }

  return 0;
};

export const calculateIncidentalTotal = (
  incidentals: SavoyaIncidentals,
): number => {
  if (!incidentals) {
    return 0.0;
  }

  return Object.values(incidentals).reduce((sum, value) => {
    return sum + (value?.reportedAmount || 0.0) / 100;
  }, 0.0);
};

export const calculateTotalIncidentalsChanges = (
  additionalChargesAmounts: Record<string, number>,
  fuelCost: number,
  setTotalIncidentals?: (value: React.SetStateAction<number>) => void,
): number => {
  const editedTotalIncidentals = Object.values(additionalChargesAmounts).reduce(
    (sum, value) => {
      return sum + (value || 0);
    },
    0,
  );
  const newTotalIncidentals = (fuelCost || 0) + editedTotalIncidentals;
  if (setTotalIncidentals) {
    setTotalIncidentals(newTotalIncidentals);
  }
  return newTotalIncidentals;
};

export const evalTransferConversion = (args: {
  assignmentId: number;
  reservation: SavoyaReservation;
  values: CloseoutFromValues;
  transferPlusWaitTimeEnabled: boolean;
  setFieldValue: (field: string, value: string | number) => void;
}): { waitCost?: number; message?: string } | null => {
  const {
    assignmentId,
    reservation,
    values,
    transferPlusWaitTimeEnabled,
    setFieldValue,
  } = args;

  const firstStop = reservation?.stops?.length ? reservation?.stops[0] : null;
  const timeZone =
    firstStop?.city?.timezone ||
    firstStop?.airport?.timezone ||
    reservation?.timezone;

  const onboardDateAdjustmentTimezoned = moment(values?.onboardDateAdjustment)
    ?.parseZone()
    ?.tz(timeZone, true)
    ?.toISOString(true);

  let waitCost = 0.0;

  const waitTimeMinutes = timeUtils.minutesBetween(
    onboardDateAdjustmentTimezoned,
    reservation?.pickupDate,
  );
  const waitTimeThreshold = reservation?.vehicleType?.greeter ? 61 : 31;

  const hasAdditionalStops = !!values?.additionalStops.length;

  if (waitTimeMinutes > waitTimeThreshold || hasAdditionalStops) {
    fetchCalculateHourlyQuote(assignmentId).then((hourlyQuote) => {
      if (hourlyQuote?.baseCost) {
        if (transferPlusWaitTimeEnabled && !hasAdditionalStops) {
          waitCost =
            timeUtils.roundUpToQuarterHours(
              waitTimeMinutes - waitTimeThreshold,
            ) * hourlyQuote.baseCost;
        } else if (hourlyQuote.type === 'hourly') {
          setFieldValue('costType', SavoyaCostType.Hourly);
          setFieldValue('minimumHours', hourlyQuote?.minimum);
          setFieldValue('baseRate', (hourlyQuote?.baseCost || 0) / 100);
        } else {
          setFieldValue('costType', SavoyaCostType.Hourly);
        }
      }
    });
    const waitTimeMessage = 'This trip qualifies for excess wait time billing.';
    const stopAddedMessage =
      'This trip converted to hourly due to a stop being added.';

    return {
      waitCost,
      message: hasAdditionalStops ? stopAddedMessage : waitTimeMessage,
    };
  }
  return {};
};
