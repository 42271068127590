import React, { useState, useEffect } from 'react';
import { snake } from 'case';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  Box,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  fade,
  makeStyles,
  styled,
  Theme,
  Switch,
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import type { Camelize } from 'src/shared/types/camelize';
import { Skeleton } from '@material-ui/lab';
import {
  SavoyaAdditionalChargeItem,
  savoyaTripCloseoutState,
  savoyaAdditionalChargesState,
} from 'src/trips/atoms/savoyaChargesAtom';
import FilesDropzone from 'src/shared/components/FilesDropzone';
import type {
  SavoyaVendorAssignment,
  SavoyaVendorProfile,
  SavoyaVendorCase,
  SavoyaVendorExchanegRate,
} from 'src/trips/types/SavoyaTripsTypes';
import { timeUtils } from 'src/shared/utils/timeUtils';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadMiscFile } from 'src/trips/api/SavoyaTripQueries';
import { useSnackbar } from 'notistack';
import { CloseoutFromValues, SavoyaCostType } from '../SavoyaPaymentDetails';
import {
  calculateIncidentalTotal,
  evalTransferConversion,
  calculateTotalIncidentalsChanges,
} from './savoyaTripRateCalculationsUtils';

type SavoyaAdditionalChargesBreakdownProps = {
  vendorAssignment: Camelize<SavoyaVendorAssignment>;
  vendorProfile: Camelize<SavoyaVendorProfile>;
  exchangeRate: Camelize<SavoyaVendorExchanegRate>;
  vendorCase: Camelize<SavoyaVendorCase>;
  editable: boolean;
  loading: boolean;
  queryKey?: (string | Record<string, unknown>)[];
};

const SavoyaAdditionalChargesBreakdown: React.FC<SavoyaAdditionalChargesBreakdownProps> =
  ({
    vendorAssignment,
    vendorProfile,
    exchangeRate,
    vendorCase,
    loading,
    editable,
    queryKey,
  }) => {
    const classes = useStyles();
    const { values, setFieldValue } = useFormikContext<CloseoutFromValues>();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [savoyaVendorAssignment, setSavoyaVendorAssignment] = useState<
      SavoyaVendorAssignment | undefined
    >(vendorAssignment);
    const [savoyaTripCloseout, setSavoyaTripCloseoutValues] = useRecoilState(
      savoyaTripCloseoutState,
    );
    const [fileUploading, setFileUploading] = useState(false);

    const uploadFile = useMutation({
      mutationFn: uploadMiscFile,
      onSuccess: (newFile) => {
        const newFiles = [
          newFile,
          ...vendorAssignment?.reservation?.fileAttachments,
        ];

        queryClient.setQueryData(
          [
            'savoyaReservationAssignmentForFullTripView',
            { reservationId: vendorAssignment?.reservation?.id?.toString() },
          ],
          (oldData: SavoyaVendorAssignment) => {
            return {
              ...oldData,
              reservation: {
                ...oldData?.reservation,
                fileAttachments: newFiles,
              },
            };
          },
        );

        queryClient.setQueryData(
          queryKey,
          (oldData: SavoyaVendorAssignment[]) => {
            const newData = oldData?.map((assignment) => {
              if (assignment?.id === vendorAssignment?.id) {
                return {
                  ...assignment,
                  reservation: {
                    ...assignment.reservation,
                    fileAttachments: newFiles,
                  },
                };
              }
              return assignment;
            });
            return newData;
          },
        );

        enqueueSnackbar('File Uploaded', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('Error Uploading File', { variant: 'error' });
      },
    });

    const setAdditionalChargesState = useSetRecoilState(
      savoyaAdditionalChargesState,
    );
    const { vendorQuote, reservation } = savoyaVendorAssignment || {};
    const { parking, airportFee, toll, misc, tax, nyCongestionFee, greeter } =
      reservation?.incidentals || {};
    const [additionalChargesAmounts, setAdditionalChargesAmounts] = useState<
      Record<string, number | undefined>
    >({
      nyCongestionFeeAmount: undefined,
      parkingAmount:
        (parking?.vendorAmount || parking?.reportedAmount || 0) / 100,
      airportFeeAmount:
        (airportFee?.vendorAmount || airportFee?.reportedAmount || 0) / 100,
      tollAmount: (toll?.vendorAmount || toll?.reportedAmount || 0) / 100,
      miscAmount: (misc?.vendorAmount || misc?.reportedAmount || 0) / 100,
      taxAmount: (tax?.vendorAmount || tax?.reportedAmount || 0) / 100,
      greeterAmount:
        (greeter?.vendorAmount || greeter?.reportedAmount || 0) / 100,
    });

    const [fuelFeeAmount, setFuelFeeAmount] = useState<number>(0.0);
    const [totalIncidentals, setTotalIncidentals] = useState<number>(0.0);
    const [backendIncidentals, setBackendIncidentals] = useState<number>(0.0);
    const [waitTimeCost, setWaitTimeCost] = useState<number>(0);
    const [showMiscFeeSupport, setShowMiscFeeSupport] = useState(
      !!values?.miscAmount,
    );

    const assignmentId = savoyaVendorAssignment?.id;
    const currencyCode =
      exchangeRate?.code || savoyaVendorAssignment?.currencyCode || 'USD';
    const editableCharges =
      (savoyaVendorAssignment?.status === 'billable' ||
        savoyaVendorAssignment?.status === 'completed') &&
      editable;

    const vendorProfileFuelSurcharge =
      (vendorProfile?.vendorFuelSurcharge ?? 0.0) / 100;
    const transferPlusWaitTimeEnabled = vendorProfile?.transferRatePlusWaitTime;

    useEffect(() => {
      // Loading the initial values of the savoya reservation
      setSavoyaVendorAssignment(vendorAssignment);
      const calculatedBackendIncidentals = calculateIncidentalTotal(
        vendorAssignment?.reservation?.incidentals,
      );
      setBackendIncidentals(calculatedBackendIncidentals);
      setTotalIncidentals(calculatedBackendIncidentals + fuelFeeAmount);
      setShowMiscFeeSupport(
        !!vendorAssignment?.reservation?.incidentals?.misc?.reportedAmount ||
          vendorAssignment?.reservation?.fileAttachments?.length > 0 ||
          !!values?.miscAmount,
      );
    }, [vendorAssignment]);

    const columns = [
      { label: 'Additional Charges', key: 'name' },
      { label: 'Amount', key: 'value' },
    ];

    const chargesList: SavoyaAdditionalChargeItem[] = [
      {
        name: 'Parking',
        key: 'parkingAmount',
        value: (additionalChargesAmounts.parkingAmount || 0).toFixed(2),
        show: true,
        editable: editableCharges,
      },
      {
        name: 'Airport Fee',
        key: 'airportFeeAmount',
        value: (additionalChargesAmounts.airportFeeAmount || 0).toFixed(2),
        show: true,
        editable: editableCharges,
      },
      {
        name: 'Tolls',
        key: 'tollAmount',
        value: (additionalChargesAmounts.tollAmount || 0).toFixed(2),
        show: true,
        editable: editableCharges,
      },
      {
        name: 'Misc Fee',
        key: 'miscAmount',
        value: (additionalChargesAmounts.miscAmount || 0).toFixed(2),
        show: true,
        editable: editableCharges,
      },
      {
        name: 'Tax',
        key: 'taxAmount',
        value: (additionalChargesAmounts.taxAmount || 0).toFixed(2),
        show: true,
        editable: editableCharges,
      },
      {
        name: 'Greeter Fee',
        key: 'greeterAmount',
        value: (additionalChargesAmounts.greeterAmount || 0).toFixed(2),
        show: reservation?.hasGreeterFee,
        editable: editableCharges,
      },
      {
        name: 'NY Congestion Fee',
        key: 'nyCongestionFeeAmount',
        value: (additionalChargesAmounts.nyCongestionFeeAmount || 0).toFixed(2),
        show: !!(
          reservation?.canAddNyCongestionFee ||
          reservation?.incidentals?.nyCongestionFee
        ),
        editable: false,
      },
      {
        name: 'Fuel Surcharge',
        key: 'fuelFeeAmount',
        value: fuelFeeAmount.toFixed(2),
        show: true,
        editable: false,
      },
    ];

    const calculateFuelCost = (): number => {
      const reportedHours = timeUtils.durationBetween(
        values?.outDateAdjustment,
        values?.inDateAdjustment,
      );
      let calculatedCost = vendorQuote?.baseCost || 0;
      if (vendorQuote?.type === 'hourly') {
        const billedHours = Math.max(
          vendorQuote?.minimum || 0,
          reportedHours || 0,
        );
        calculatedCost *= billedHours;
      }
      const compensationPercentage =
        (vendorCase?.vendorCompensationPercentage || 0) / 100;
      const invertedCompensation =
        savoyaTripCloseout?.invertedCompensation ||
        (100 - compensationPercentage) / 100;
      let fuelFeeCost = 0.0;
      if (['paid', 'finalized', 'closed_out'].includes(reservation?.status)) {
        fuelFeeCost = (vendorQuote?.vendorFuelFee ?? 0.0) / 100;
      } else {
        fuelFeeCost =
          (vendorProfileFuelSurcharge *
            Math.round(
              calculatedCost * invertedCompensation +
                waitTimeCost +
                backendIncidentals,
            )) /
          10000;
      }
      setFuelFeeAmount(fuelFeeCost);
      return fuelFeeCost;
    };

    const updateAdditionalCharges = (key: string, fieldValue: string) => {
      setAdditionalChargesAmounts({
        ...additionalChargesAmounts,
        [key]: parseFloat(fieldValue),
      });
      const newChanges = chargesList.map((charge) => {
        if (charge.key === key) {
          return {
            ...charge,
            value: fieldValue ? parseFloat(fieldValue).toFixed(2) : '0.00',
          };
        }
        return charge;
      });
      setAdditionalChargesState(newChanges);
    };

    useEffect(() => {
      calculateFuelCost();

      if (values?.costType !== SavoyaCostType.Hourly) {
        const { waitCost, message } = evalTransferConversion({
          assignmentId,
          reservation,
          values,
          transferPlusWaitTimeEnabled,
          setFieldValue,
        });

        if (waitCost > 0 && values?.additionalStops?.length < 1) {
          setWaitTimeCost(waitCost);
        }

        if (message) {
          enqueueSnackbar(message, { variant: 'warning' });
        }
      }

      if (Number.isNaN(fuelFeeAmount)) {
        setFuelFeeAmount(0.0);
      }

      setAdditionalChargesAmounts((oldValues) => ({
        ...oldValues,
        parkingAmount:
          values?.parkingAmount ||
          (parking?.vendorAmount || parking?.reportedAmount || 0) / 100,
        airportFeeAmount:
          values?.airportFeeAmount ||
          (airportFee?.vendorAmount || airportFee?.reportedAmount || 0) / 100,
        tollAmount:
          values?.tollAmount ||
          (toll?.vendorAmount || toll?.reportedAmount || 0) / 100,
        miscAmount:
          values?.miscAmount ||
          (misc?.vendorAmount || misc?.reportedAmount || 0) / 100,
        taxAmount:
          values?.taxAmount ||
          (tax?.vendorAmount || tax?.reportedAmount || 0) / 100,
        greeterAmount:
          values?.greeterAmount ||
          (greeter?.vendorAmount || greeter?.reportedAmount || 0) / 100,
      }));

      if (
        reservation?.canAddNyCongestionFee ||
        reservation?.incidentals?.nyCongestionFee
      ) {
        setAdditionalChargesAmounts((oldValue) => ({
          ...oldValue,
          nyCongestionFeeAmount:
            values?.nyCongestionFeeAmount ||
            nyCongestionFee?.vendorAmount ||
            nyCongestionFee?.reportedAmount ||
            0.0,
        }));
      }

      calculateTotalIncidentalsChanges(
        additionalChargesAmounts,
        fuelFeeAmount,
        setTotalIncidentals,
      );
      setAdditionalChargesState(chargesList);

      setSavoyaTripCloseoutValues((oldValues) => ({
        ...oldValues,
        waitTimeCost,
      }));
    }, [values]);

    useEffect(() => {
      const fuelCost = calculateFuelCost();
      calculateTotalIncidentalsChanges(
        additionalChargesAmounts,
        fuelCost,
        setTotalIncidentals,
      );
    }, [savoyaTripCloseout?.invertedCompensation]);

    useEffect(() => {
      setAdditionalChargesState(chargesList);
      calculateTotalIncidentalsChanges(
        additionalChargesAmounts,
        fuelFeeAmount,
        setTotalIncidentals,
      );
      setShowMiscFeeSupport(additionalChargesAmounts?.miscAmount > 0);
    }, [additionalChargesAmounts]);

    const onDrop = async (files: File[]) => {
      setFileUploading(true);
      if (!files?.length) {
        return;
      }
      const file = files[0];

      await uploadFile.mutateAsync({
        reservationId: vendorAssignment?.reservation?.id,
        file,
      });
      setFileUploading(false);
    };

    return (
      <Grid item className={classes.grid}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {(loading ? Array.from(new Array(2)) : columns).map(
                  (column, index) => {
                    if (!column) {
                      return (
                        <TableCell key={index}>
                          <Skeleton width="100%" />
                        </TableCell>
                      );
                    }
                    return <TableCell key={index}>{column.label}</TableCell>;
                  },
                )}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {(loading ? Array.from(new Array(5)) : chargesList).map(
                (row: SavoyaAdditionalChargeItem, index) => {
                  if (!row) {
                    return (
                      <TableRow key={index} className={classes.tableRow}>
                        {Array.from(new Array(2)).map((_, index) => (
                          <TableCell key={index}>
                            <Skeleton width="100%" />
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  }

                  return (
                    <TableRow key={index} className={classes.tableRow}>
                      {columns.map((column) => {
                        const value = row[column.key];
                        const { key, name, show, editable } = row;
                        if (!show) {
                          return null;
                        }
                        if (column?.key === 'name') {
                          return (
                            <CustomCell
                              key={`${column.key}-${name}`}
                              style={{ width: '80%' }}
                            >
                              <Box>
                                {value}
                                {key === 'nyCongestionFeeAmount' &&
                                reservation?.status === 'completed' ? (
                                  <Switch
                                    size="small"
                                    color="primary"
                                    onChange={async (_event, checked) => {
                                      setFieldValue(
                                        'nyCongestionFeeAmount',
                                        checked ? 2.75 : 0.0,
                                      );
                                    }}
                                  />
                                ) : null}
                              </Box>
                            </CustomCell>
                          );
                        }
                        return editable ? (
                          <CustomCell key={`${column.key}-${name}`}>
                            <Field id={key} name={key}>
                              {({ field, form }) => {
                                return (
                                  <TextField
                                    {...field}
                                    data-testid={`savoya_additional_charge_${snake(
                                      name,
                                    )}`}
                                    placeholder="0.00"
                                    type="number"
                                    variant="outlined"
                                    prev
                                    onWheel={(e) =>
                                      e.target instanceof HTMLElement &&
                                      e.target.blur()
                                    }
                                    size="small"
                                    disabled={!editable}
                                    style={{ width: 100 }}
                                    onChange={(e) => {
                                      updateAdditionalCharges(
                                        key,
                                        e.target.value,
                                      );
                                      form.setFieldValue(
                                        field.name,
                                        parseFloat(e.target.value) || null,
                                      );
                                    }}
                                    value={
                                      field?.value ||
                                      +additionalChargesAmounts[key]?.toFixed(
                                        2,
                                      ) ||
                                      ''
                                    }
                                  />
                                );
                              }}
                            </Field>
                          </CustomCell>
                        ) : (
                          <CustomCell
                            key={`${column.key}-${name}`}
                            style={{
                              textAlign: 'right',
                              paddingRight: 25,
                            }}
                          >
                            {value}
                          </CustomCell>
                        );
                      })}
                    </TableRow>
                  );
                },
              )}
              {showMiscFeeSupport ? (
                <React.Fragment>
                  <TableRow className={classes.tableRow}>
                    <CustomCell colSpan={2}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        height={45}
                      >
                        <Typography variant="body1" className={classes.text}>
                          Misc Fee Note (Required)
                        </Typography>
                        <Field id="miscFeeNote" name="miscFeeNote">
                          {({ field }) => (
                            <TextField
                              {...field}
                              data-testid="savoya_misc_fee_notes"
                              variant="outlined"
                              size="small"
                              style={{ width: '70%' }}
                              value={field?.value}
                              disabled={!editableCharges}
                            />
                          )}
                        </Field>
                      </Box>
                    </CustomCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <CustomCell colSpan={2}>
                      <Box style={{ marginBottom: 10 }}>
                        <Typography variant="body1" className={classes.text}>
                          Support for Misc Fee
                        </Typography>
                      </Box>
                      {editableCharges ? (
                        <Box>
                          <FilesDropzone
                            options={{
                              disabled: !editableCharges,
                              useFsAccessApi: false,
                              multiple: true,
                              maxSize: 16000000,
                              accept: {
                                'application/pdf': ['.pdf'],
                                'image/png': ['.png'],
                                'image/jpeg': ['.jpeg', '.jpg'],
                              },
                              onDrop,
                              onDropRejected: (files) => {
                                setFileUploading(false);
                                const file = files[0];
                                if (file?.file?.size > 16000000) {
                                  enqueueSnackbar('Maximum File Size: 16MB', {
                                    variant: 'error',
                                  });
                                } else if (file?.errors?.length) {
                                  enqueueSnackbar(file?.errors[0]?.message, {
                                    variant: 'error',
                                  });
                                } else {
                                  enqueueSnackbar('Unknown File Error', {
                                    variant: 'error',
                                  });
                                }
                              },
                            }}
                            uploading={fileUploading}
                          />
                        </Box>
                      ) : null}
                    </CustomCell>
                  </TableRow>
                  {vendorAssignment?.reservation?.fileAttachments?.length ? (
                    <Box margin={1}>
                      <Typography
                        style={{ fontWeight: 'bold' }}
                        display="inline"
                      >
                        {'Files Attached: '}
                      </Typography>
                      <Typography
                        style={{ fontStyle: 'italic' }}
                        display="inline"
                      >
                        {vendorAssignment?.reservation?.fileAttachments
                          ?.map((attachment) => attachment?.attachmentFileName)
                          ?.join(', ')}
                      </Typography>
                    </Box>
                  ) : null}
                </React.Fragment>
              ) : null}
              <TableRow className={classes.tableRow}>
                <CustomCell>
                  <Typography>Total Incidentals</Typography>
                </CustomCell>
                <CustomCell>
                  <Typography>
                    {getSymbolFromCurrency(currencyCode)}{' '}
                    {totalIncidentals.toFixed(2)}
                  </Typography>
                </CustomCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

export const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
    padding: '10px',
  },
  tableHead: {
    '& > .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
      color: theme.palette.textDark.main,
      '& > .MuiTableCell-root.MuiTableCell-head:not(:first-child)': {
        textAlign: 'right',
      },
      '& > .MuiTableCell-root.MuiTableCell-head': {
        paddingLeft: theme.spacing(2),
      },
    },
  },
  tableBody: {
    '& > .MuiTableRow-root:last-child': {
      backgroundColor: fade(theme.palette.backgroundColor.main, 0.3),
      alignItems: 'center',
      jsutifyContent: 'center',
    },
  },
  tableRow: {
    '& > .MuiTableCell-root.MuiTableCell-body:not(:first-child)': {
      textAlign: 'right',
    },
    '& > .MuiTableCell-root.MuiTableCell-body': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: 45,
      textAlign: 'left',
    },
  },
  text: {
    fontSize: 14,
  },
}));

const CustomCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  backgroundColor: 'transparent',
}));

export default SavoyaAdditionalChargesBreakdown;
