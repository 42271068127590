import React, { FC, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {
  CreditCardProcessor,
  Trip,
  useExecuteCreatePaymentMethodMutation,
  TripClientRecord,
  ClientRole,
} from 'src/.gen/graphql';
import { SetupIntent } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { DeepPartial } from 'react-hook-form';
import { ContainerActions } from '../StepWizard/StepWizardFooter';

type SetupFormProps = {
  returnUrl: string;
  trip: Partial<Trip>;
  tripClients?: DeepPartial<TripClientRecord>[];
  closeSetup: (
    newPaymentMethodId: string,
    newPaymentMethodDescription: string,
  ) => void;
};

const SetupForm: FC<SetupFormProps> = ({
  returnUrl,
  trip,
  tripClients,
  closeSetup,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [createPaymentMethod] = useExecuteCreatePaymentMethodMutation();

  const PASSENGER = 'passenger';
  const BOOKER = 'booker';
  const DONT_SAVE = 'none';
  const [checkbox, setCheckbox] = useState<string>(PASSENGER);

  const savePaymentMethod = async (setupIntent: SetupIntent) => {
    let clientId = null;
    const checkboxParam = searchParams.get('checkbox');

    if (checkboxParam === PASSENGER) {
      const passenger = tripClients?.find(
        (tripClient) => !!tripClient?.isPrimary,
      );
      clientId = passenger?.clientId;
    }
    if (checkboxParam === BOOKER) {
      const booker = tripClients?.find(
        (tripClient) => tripClient?.role === ClientRole.Booker,
      );
      clientId = booker?.clientId;
    }

    if (checkboxParam === DONT_SAVE) {
      clientId = null;
    }

    const paymentCreated = await createPaymentMethod({
      variables: {
        tripId: trip.id,
        externalPaymentMethodId: setupIntent.payment_method.toString(),
        customerContactId: clientId,
        source: CreditCardProcessor.Stripe,
      },
      refetchQueries: ['GetTripById'],
    });

    if (paymentCreated.data.executeCreatePaymentMethod.succeeded) {
      // window.location.assign(returnUrl);
      searchParams.delete('setup_intent_client_secret');
      searchParams.delete('setup_intent');
      searchParams.delete('redirect_status');
      searchParams.delete('checkbox');
      const queryParams = searchParams.toString();
      setSearchParams(queryParams, { replace: false });
      closeSetup(
        paymentCreated.data.executeCreatePaymentMethod.trip.paymentMethodId,
        paymentCreated.data.executeCreatePaymentMethod.trip.paymentMethod
          .description,
      );
      enqueueSnackbar('Success! Your payment method has been updated.', {
        variant: 'success',
        preventDuplicate: false,
      });
    }
  };

  React.useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret',
    );

    if (clientSecret) {
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (setupIntent.status) {
          case 'succeeded':
            savePaymentMethod(setupIntent as SetupIntent);
            break;

          case 'processing':
            enqueueSnackbar(
              "Processing payment details. We'll update you when processing is complete.",
              {
                variant: 'error',
                preventDuplicate: true,
                persist: true,
              },
            );
            break;
          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            enqueueSnackbar(
              'Failed to process payment details. Please try another payment method.',
              {
                variant: 'error',
                preventDuplicate: true,
                persist: true,
              },
            );
            break;
          default:
            // eslint-disable-next-line no-console
            console.log('DEFAULT Failed to process payment details');
            break;
        }
      });
    }
    // Retrieve the SetupIntent
  }, [stripe]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const completeReturnUrl = returnUrl.includes('?')
      ? `${returnUrl}&checkbox=${checkbox}`
      : `${returnUrl}?checkbox=${checkbox}`;

    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: completeReturnUrl,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
      });
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const loading = !stripe || !elements;

  return loading ? (
    <Typography variant="h5">Loading</Typography>
  ) : (
    <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '1rem' }}>
      <PaymentElement />
      <div style={{ display: 'flex', marginTop: '2rem', marginBottom: '2rem' }}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={checkbox}
            onChange={(e) => {
              setCheckbox(e.target.value);
            }}
          >
            <FormControlLabel
              control={<Radio />}
              value={PASSENGER}
              label="Save this card to passenger profile for future payments"
            />
            {!trip?.wasBookedByPassenger && (
              <FormControlLabel
                value={BOOKER}
                control={<Radio />}
                label="Save this card to booker profile for future payments"
              />
            )}
            <FormControlLabel
              control={<Radio />}
              value={DONT_SAVE}
              label="Don't save this card for future payments"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <ContainerActions>
        <Button
          disabled={loading}
          color="primary"
          variant="outlined"
          onClick={() => closeSetup(undefined, undefined)}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          type="submit"
        >
          Add card
        </Button>
      </ContainerActions>
    </form>
  );
};

export default SetupForm;
