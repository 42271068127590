import { RateCalculation, InvoiceSummary } from 'src/.gen/graphql';
import { pick, sortBy } from 'lodash';
import { splitByCamelCase } from 'src/shared/utils/stringUtils';
import { differenceByHours, formatISODate } from '../TripLog/utils';

export interface InvoiceTimesRow {
  status: string;
  scheduledTime: string;
  actualTime: string;
  garageTime?: number | null;
  waitTime?: number | null;
}

export interface ColumnInvoiceTimes {
  id: keyof InvoiceTimesRow;
  label: string;
}

const INVOICE_TIMES_TABLE_HEADERS_HOURLY: ColumnInvoiceTimes[] = [
  { id: 'status', label: 'Status' },
  { id: 'scheduledTime', label: 'Scheduled' },
  { id: 'actualTime', label: 'Actual' },
  { id: 'garageTime', label: 'Garage Time' },
];

const INVOICE_TIMES_TABLE_HEADERS_TRANSFER: ColumnInvoiceTimes[] = [
  { id: 'status', label: 'Status' },
  { id: 'scheduledTime', label: 'Scheduled' },
  { id: 'actualTime', label: 'Actual' },
  { id: 'waitTime', label: 'Billed Wait Time' },
];

const invoiceTimesHourlyTimestamps = [
  'tripStartedTime',
  'pickupTime',
  'dropoffTime',
  'tripCompletedTime',
];

const invoiceTimesTranferTimestamps = ['pickupTime', 'dropoffTime'];

export const getInvoiceTimesColumns = (
  rateCalculation: RateCalculation,
): ColumnInvoiceTimes[] => {
  switch (rateCalculation) {
    case RateCalculation.PerHour:
      return INVOICE_TIMES_TABLE_HEADERS_HOURLY;
    case RateCalculation.Flat:
      return INVOICE_TIMES_TABLE_HEADERS_TRANSFER;
    default:
      return [];
  }
};

export const isTripRateTypeHourly = (
  rateCalculation: RateCalculation,
): boolean => {
  return rateCalculation === RateCalculation.PerHour;
};

export const parseInvoiceTimeTotal = (
  summary: InvoiceSummary,
): InvoiceTimesRow => {
  const startTime = summary.tripStartedTime;
  const endTime = summary.tripCompletedTime;

  return {
    status: 'Total',
    scheduledTime: differenceByHours(
      startTime.scheduledTime,
      endTime.scheduledTime,
    ),
    actualTime: differenceByHours(startTime.billedTime, endTime.billedTime),
    garageTime: null,
  };
};

export const getInvoiceTimesRows = (
  summary: InvoiceSummary,
): InvoiceTimesRow[] => {
  const filterTimestamps = getFilterTimeStamps(summary?.calculation);
  const invoiceTimestamps = pick(summary, filterTimestamps);
  return sortBy(Object.keys(invoiceTimestamps ?? {}), (key) =>
    invoiceTimesHourlyTimestamps.indexOf(key),
  ).map((key) => {
    const times = getGarageTimeByTimestamp(summary, key);
    return {
      status: getLabelForTimeStamps(key),
      ...times,
    } as InvoiceTimesRow;
  });
};

function getFilterTimeStamps(rateCalculation: RateCalculation): string[] {
  switch (rateCalculation) {
    case RateCalculation.PerHour:
      return invoiceTimesHourlyTimestamps;
    case RateCalculation.Flat:
      return invoiceTimesTranferTimestamps;
    default:
      return [];
  }
}

function getGarageTimeByTimestamp(
  summary: InvoiceSummary,
  timestamp: string,
): Partial<InvoiceTimesRow> {
  const { scheduledTime = null, billedTime = null } = summary[timestamp] ?? {};
  function getBilledTime(): Record<string, number> {
    switch (true) {
      case summary.calculation === RateCalculation.Flat &&
        timestamp === 'pickupTime':
        return { waitTime: summary.waitQuantity };
      case summary.calculation === RateCalculation.PerHour &&
        timestamp === 'tripStartedTime':
        return { garageTime: summary.pickupGarageTime };
      case summary.calculation === RateCalculation.PerHour &&
        timestamp === 'tripCompletedTime':
        return { garageTime: summary.dropoffGarageTime };
      default:
        return null;
    }
  }

  return {
    scheduledTime: formatISODate(scheduledTime, 'hh:mm aaa'),
    actualTime: formatISODate(billedTime, 'hh:mm aaa'),
    ...getBilledTime(),
  };
}

function getLabelForTimeStamps(key: string) {
  switch (key) {
    case 'tripStartedTime':
      return 'Trip Started';
    case 'pickupTime':
      return 'Pickup';
    case 'dropoffTime':
      return 'Dropoff';
    case 'tripCompletedTime':
      return 'Trip Completed';
    default:
      return splitByCamelCase(key);
  }
}
