/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addMinutes,
  isSameDay,
  format,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import {
  TripFormFormattedPickupOrDropoffWaypoint,
  TripFormFormattedWaypoint,
} from 'src/trips/atoms/tripFormAtom/tripFormAtomTypes';
import { ExtendedTripWaypoint } from 'src/trips/components/TripMapTile/TripMapTile';
import {
  TripWaypoint,
  TripWaypointRole,
  Location,
  Trip,
  DistancesAndTime,
} from '../../.gen/graphql';
import { convertSecondsToMinutes } from './conversions';

export interface AddressInformation {
  airline:
    | {
        name: string | undefined;
        flightNumber: string | undefined;
        iata: string | undefined;
        tailNumber: string | undefined;
      }
    | undefined;
  placeName: string;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  placeCategory: string;
  verifiedFlight?: any;
  isPrivateFlight: boolean;
  flightTime: Date | null;
}

export const parseCoreAddress = (
  waypoint: ExtendedTripWaypoint,
  overrideDroppoffDetails: boolean,
): Omit<AddressInformation, 'airline'> => {
  const overrideDropoff =
    waypoint.role === TripWaypointRole.Dropoff && overrideDroppoffDetails;

  const completeAddress =
    waypoint?.savoyaStop?.name || waypoint?.location?.name || '';
  const placeName = overrideDropoff ? '' : waypoint?.location?.addressLine1;
  const savoyaFlightInfo = waypoint?.savoyaFlightInfo ?? {};
  const { state, city, postalCode, placeCategory } = waypoint?.location || {};
  const isAirport =
    placeCategory === 'airport' || savoyaFlightInfo?.airport?.id;
  const isPrivateFlight =
    isAirport && (!!waypoint?.fboId || savoyaFlightInfo?.flight?.private);
  const flightTime =
    (waypoint.flightTime && new Date(waypoint.flightTime)) ||
    (savoyaFlightInfo?.flight?.scheduledTime &&
      new Date(savoyaFlightInfo?.flight?.scheduledTime));

  const streetName = overrideDropoff
    ? 'As Directed'
    : completeAddress
        ?.replace(placeName, '')
        .replace(',', '')
        .replace(', United States', '')
        .split(',')[0]
        .trim();
  return {
    placeName,
    streetName,
    city,
    state,
    postalCode,
    placeCategory,
    verifiedFlight: parseVerifiedFlight(waypoint),
    isPrivateFlight,
    flightTime,
  };
};

export const parseAirline = (
  waypoint: ExtendedTripWaypoint,
): Pick<AddressInformation, 'airline'> => {
  const savoyaFlightInfo = waypoint?.savoyaFlightInfo ?? {};
  const flightNumber =
    savoyaFlightInfo?.flight?.flightNumber ??
    waypoint?.flightNumber ??
    undefined;
  const name =
    savoyaFlightInfo?.flight?.airline?.name ??
    savoyaFlightInfo?.flight?.fbo?.name ??
    waypoint?.airline?.name ??
    waypoint?.fbo?.fbo_name ??
    undefined;
  const iata =
    savoyaFlightInfo?.airport?.iataCode ??
    waypoint?.airline?.IATA ??
    waypoint?.fbo?.iata_code ??
    undefined;
  const tailNumber = savoyaFlightInfo?.flight?.tailNumber;
  return {
    airline: {
      name,
      flightNumber,
      iata,
      tailNumber,
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseVerifiedFlight = (waypoint: ExtendedTripWaypoint): any => {
  const { verifiedFlight, role, savoyaFlightInfo } = waypoint;

  if (savoyaFlightInfo?.flight) {
    const delay =
      savoyaFlightInfo?.flight?.actualTime &&
      savoyaFlightInfo?.flight?.scheduledTime
        ? differenceInSeconds(
            new Date(savoyaFlightInfo?.flight?.actualTime),
            new Date(savoyaFlightInfo?.flight?.scheduledTime),
          )
        : null;
    return {
      delay,
      gate:
        waypoint?.role === TripWaypointRole.Dropoff
          ? savoyaFlightInfo?.flight?.departureGate
          : null,
      baggageClaim:
        waypoint?.role === TripWaypointRole.Pickup
          ? savoyaFlightInfo?.flight?.arrivalBaggageClaim
          : null,
    };
  }

  if (!verifiedFlight) {
    return null;
  }
  if (typeof verifiedFlight.success === 'boolean' && !verifiedFlight.success) {
    const error = verifiedFlight?.errors[0];
    return {
      error: true,
      errorMessage: error?.message || 'Not found error',
      terminal: '',
      gate: '',
    };
  }

  switch (role) {
    case TripWaypointRole.Pickup:
      return {
        terminal: verifiedFlight.terminal_destination,
        gate: verifiedFlight.gate_destination,
        baggageClaim: verifiedFlight.baggage_claim,
        delay: getDelayByWaypoint(verifiedFlight, TripWaypointRole.Pickup),
        cancelled: verifiedFlight.cancelled,
      };
    case TripWaypointRole.Dropoff:
      return {
        terminal: verifiedFlight.terminal_origin,
        gate: verifiedFlight.gate_origin,
      };
    default:
      return null;
  }
};

export const parseWaypoint = (
  waypoint: ExtendedTripWaypoint,
  overrideDroppoffDetails = false,
): AddressInformation => {
  if (waypoint) {
    const parsedCoreAddress = parseCoreAddress(
      waypoint,
      overrideDroppoffDetails,
    );
    const parsedAirline = parseAirline(waypoint);
    return { ...parsedCoreAddress, ...parsedAirline };
  }
  return {
    placeName: 'Unspecified Location',
    streetName: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined,
    airline: undefined,
    placeCategory: undefined,
    isPrivateFlight: false,
    flightTime: null,
  };
};

export const getActualEstimatedDurationInMinutes = (
  trip: Partial<Trip>,
): number => {
  const { pickupWaypoint, dropoffWaypoint } = trip ?? {};
  if (!pickupWaypoint && !dropoffWaypoint) {
    return 0;
  }
  const estimatedDepartureTime = new Date(
    pickupWaypoint?.departureTime ?? pickupWaypoint?.estimatedDepartureTime,
  );
  const estimatedArrivalTime = new Date(
    dropoffWaypoint?.arrivalTime ?? dropoffWaypoint?.estimatedArrivalTime,
  );

  return differenceInMinutes(estimatedArrivalTime, estimatedDepartureTime);
};

export const getEstimatedArrivalTime = (
  pickupTime: Date | string,
  pickupWaypoint: Partial<TripWaypoint>,
  endWaypoint: Partial<TripWaypoint>,
): string => {
  const initalDate =
    typeof pickupTime === 'string' ? new Date(pickupTime) : pickupTime;

  /**
   * This just applies beacuse in the BE there is a DEFAULT_SPOT_TIME of 15 min,
   * so the driver will arrive 15min earlier plus a 5 min spot time that give us this difference.
   * -15 min + 5 min = 10 min
   */

  const diffMinutes = differenceInMinutes(
    new Date(pickupWaypoint.estimatedDepartureTime),
    initalDate,
  );

  const estimatedArrivalTime = addMinutes(
    new Date(endWaypoint.estimatedArrivalTime),
    diffMinutes,
  );

  if (isSameDay(initalDate, estimatedArrivalTime)) {
    return format(estimatedArrivalTime, 'hh:mm aaa');
  }

  return format(estimatedArrivalTime, "eee, MMM dd 'at' hh:mm aaa");
};

export const parsePlaceName = (placeName: string, iataCode: string): string => {
  const hasIataCode = !!getIATCodeFromPlaceName(placeName);
  if (hasIataCode || !iataCode) {
    return placeName;
  }

  return `${placeName} (${iataCode})`;
};

export const getIATCodeFromPlaceName = (placeName: string): string => {
  const regExp = /\(([^)]+)\)/;
  const matches = regExp.exec(placeName);
  return matches?.length ? matches[1] : '';
};

export const getDefaultValueAirlineFromWaypoint = (
  waypoint: Partial<TripWaypoint>,
): { id: string; label?: string } => {
  if (waypoint?.airlineId) {
    return {
      id: waypoint.airlineId,
      label: waypoint.airline?.name,
    };
  }
  return { id: 'not_set' };
};

export const getTotalStopWaypoints = (
  waypoints: Partial<TripWaypoint>[] = [],
): number => {
  return (
    waypoints?.filter(({ role }) => role === TripWaypointRole.Stop).length || 0
  );
};

export const getVerifiedFlightDetailsText = (
  isPrivateFlight: boolean,
  flightTime: Date | null,
  verifiedFlight: any,
): string => {
  const parseValue = (value) => {
    return value ?? '--';
  };

  if (isPrivateFlight && flightTime) {
    return `Flight Date: ${format(
      flightTime,
      'MM/dd/yyyy',
    )} Flight Time: ${format(flightTime, 'hh:mm aaa')}`;
  }

  if (!verifiedFlight) {
    return '';
  }

  if (verifiedFlight.error) {
    return verifiedFlight.errorMessage;
  }
  let flightText = '';

  if (verifiedFlight.terminal) {
    flightText += `Terminal: ${parseValue(verifiedFlight.terminal)}`;
  }
  if (verifiedFlight?.gate) {
    flightText += ` Gate: ${parseValue(verifiedFlight.gate)}`;
  }
  if (verifiedFlight?.baggageClaim) {
    flightText += ` Baggage Claim: ${parseValue(verifiedFlight.baggageClaim)}`;
  }

  return flightText.trim();
};

export function getVerifiedFlightDelayLabel(
  delay: number,
  cancelled: boolean,
): string {
  if (cancelled) {
    return 'Cancelled';
  }

  if (delay === 0) {
    return '';
  }

  return `${convertSecondsToMinutes(Math.abs(delay))} minutes ${
    delay > 0 ? 'late' : 'early'
  }`;
}

function getDelayByWaypoint(
  verifiedFlight: any,
  waypointRole: TripWaypointRole,
): number {
  switch (waypointRole) {
    case TripWaypointRole.Pickup:
      return verifiedFlight.arrival_delay;
    case TripWaypointRole.Dropoff:
      return verifiedFlight.departure_delay;
    default:
      return 0;
  }
}

export const parseLocation = (location: Partial<Location>): string => {
  return [
    location?.placeName,
    location?.city,
    location?.state,
    location?.postalCode,
  ]
    .filter((value) => value)
    .join(', ');
};

export const filterGarageSegments = (
  timesAndDistances: Partial<DistancesAndTime>[],
): Partial<DistancesAndTime>[] => timesAndDistances.slice(1, -1);

export const formatLocationToAddress = (
  location: Partial<Location>,
): string => {
  const { addressLine1, placeName, city, state, postalCode } = location ?? {};
  return [addressLine1 || placeName, city, state, postalCode]
    .filter((value) => !!value)
    .join(', ');
};

export const isValidAirport = (
  location:
    | TripFormFormattedPickupOrDropoffWaypoint
    | TripFormFormattedWaypoint,
): boolean => {
  if (!location) {
    return false;
  }
  const hasIataCode = !!getIATCodeFromPlaceName(location.placeName);
  return location.placeCategory === 'airport' && hasIataCode;
};

export const isLocationAirport = (location: Partial<Location>) => {
  if (!location) {
    return false;
  }
  const hasIataCode = !!getIATCodeFromPlaceName(location.placeName);
  return location.placeCategory === 'airport' && hasIataCode;
};
