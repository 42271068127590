import React, { FC } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import {
  GenericDriver,
  GenericVehicle,
  GenericVehicleClass,
  Trip,
} from 'src/.gen/graphql';
import { useRecoilState } from 'recoil';
import { ContainerActions } from 'src/shared/components/StepWizard/StepWizardFooter';
import { tripAssignmentSelector } from 'src/tripsNewClients/atoms/tripAssignmentAtom';
import DriversDropdown from './components/DriversDropdown';
import VehiclesDropdown from './components/VehiclesDropdown';

interface TripAssignmentFormProps {
  trip: Partial<Trip>;
  onCancel?: () => void;
  onSuccess?: (
    vehicle: Partial<GenericVehicle>,
    driver: Partial<GenericDriver>,
  ) => void;
  loading?: boolean;
  noCompUpgrade?: boolean;
}

const TripAssignmentForm: FC<TripAssignmentFormProps> = ({
  trip,
  onCancel,
  onSuccess,
  loading,
  noCompUpgrade,
}) => {
  const [currentTripAssignmentInfo, overwriteCurrentAssignmentInfo] =
    useRecoilState(tripAssignmentSelector);

  const [driver, setDriver] = React.useState(
    currentTripAssignmentInfo?.driver || undefined,
  );
  const [vehicleClass, setVehicleClass] = React.useState<
    Partial<GenericVehicleClass>
  >(
    currentTripAssignmentInfo?.vehicleClass ||
      currentTripAssignmentInfo?.preferredVehicleClass ||
      trip?.preferredVehicleClass,
  );
  const [vehicle, setVehicle] = React.useState(
    currentTripAssignmentInfo?.vehicle || undefined,
  );

  const setAssignation = () => {
    overwriteCurrentAssignmentInfo({
      driver,
      vehicle,
      vehicleClass,
      isAssigned: true,
    });
    if (onSuccess) {
      onSuccess(vehicle, driver);
    }
  };
  const resetAssignation = () => {
    setDriver(currentTripAssignmentInfo?.driver || undefined);
    setVehicleClass(
      currentTripAssignmentInfo.vehicleClass ||
        currentTripAssignmentInfo.preferredVehicleClass ||
        undefined,
    );
    setVehicle(currentTripAssignmentInfo?.vehicle || undefined);
  };

  const unableToConfirm = !vehicleClass || !driver || !vehicle;
  return (
    <Grid
      container
      spacing={2}
      style={{
        marginBottom: '2rem',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'flex-start',
        position: 'relative',
      }}
    >
      {vehicleClass ? (
        <Grid item xs={12}>
          <DriversDropdown
            driver={driver}
            setSelectedDriver={setDriver}
            tripId={trip.id}
          />
          {driver && (
            <Typography variant="caption">
              This chauffeur will be notified of their new assignment.
            </Typography>
          )}
        </Grid>
      ) : null}
      {vehicleClass ? (
        <Grid item xs={12}>
          <VehiclesDropdown
            vehicle={vehicle}
            setSelectedVehicle={setVehicle}
            setSelectedVehicleClass={setVehicleClass}
            tripId={trip.id}
          />
        </Grid>
      ) : null}
      {noCompUpgrade ? (
        <Grid item xs={12}>
          <Typography color="error">No Comp Upgrade Allowed</Typography>
        </Grid>
      ) : null}
      <Grid item xs={12} style={{ marginTop: '1rem' }}>
        <ContainerActions>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              resetAssignation();
              if (typeof onCancel === 'function') {
                onCancel();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={async () => setAssignation()}
            disabled={unableToConfirm || loading}
          >
            {loading ? <CircularProgress /> : 'Assign Trip'}
          </Button>
        </ContainerActions>
      </Grid>
    </Grid>
  );
};

export default TripAssignmentForm;
