import React from 'react';
import { Typography, styled, Theme } from '@material-ui/core';
import {
  getVerifiedFlightDelayLabel,
  getVerifiedFlightDetailsText,
} from '../utils/addressUtils';

interface VerifiedFlightProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifiedFlight: any;
  flightTime: Date | null;
  isPrivateFlight?: boolean;
  forTile?: boolean;
}

const getColorLabel = (delay, cancelled, theme): string => {
  switch (true) {
    case delay > 0:
      return theme.palette.secondary.dark;
    case delay < 0:
    case cancelled:
      return theme.palette.error.main;
    default:
      return 'transparent';
  }
};

const VerifiedFlightDetails: React.FC<VerifiedFlightProps> = ({
  verifiedFlight,
  flightTime,
  isPrivateFlight,
  forTile,
}) => {
  return (
    <Typography variant="caption" color="textSecondary">
      {!forTile
        ? getVerifiedFlightDetailsText(
            isPrivateFlight,
            flightTime,
            verifiedFlight,
          )
        : null}
      {verifiedFlight?.delay || verifiedFlight?.cancelled ? (
        <DelayLabel
          variant="caption"
          delay={verifiedFlight.delay}
          cancelled={verifiedFlight.cancelled}
          forTile={forTile}
        >
          {getVerifiedFlightDelayLabel(
            verifiedFlight?.delay,
            verifiedFlight?.cancelled,
          )}
        </DelayLabel>
      ) : null}
    </Typography>
  );
};

const DelayLabel = styled(Typography)(
  ({
    theme,
    delay,
    cancelled,
    forTile,
  }: {
    theme: Theme;
    delay: number;
    cancelled: number;
    forTile: boolean;
  }) => ({
    color: getColorLabel(delay, cancelled, theme),
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: !forTile ? theme.spacing(1) : null,
    display: 'block',
  }),
);

export default VerifiedFlightDetails;
