import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  ClientRole,
  Trip,
  TripClientRecord,
  useExecuteCreateInvoiceDeliveryRequestMutation,
} from 'src/.gen/graphql';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { DeepPartial } from 'react-hook-form';

interface SendInvoiceProps {
  trip: Partial<Trip>;
  tripClients: DeepPartial<TripClientRecord>[];
}

const SendInvoicePDF: React.FC<SendInvoiceProps> = ({ trip, tripClients }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [createInvoiceDeliveryRequest] =
    useExecuteCreateInvoiceDeliveryRequestMutation();

  const passengerClient = tripClients?.find(
    (tripClient) => !!tripClient?.isPrimary,
  );
  const bookerClient = tripClients?.find(
    (tripClient) => tripClient?.role === ClientRole.Booker,
  );
  const bookerEmail = bookerClient?.client?.emails?.length
    ? bookerClient?.client?.emails[0]?.email
    : null;
  const passengerEmail = passengerClient?.client?.emails?.length
    ? passengerClient?.client?.emails[0]?.email
    : null;
  const defaultEmail = bookerEmail || passengerEmail;
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: defaultEmail ?? '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Contact email is required')
        .email('Please enter a valid email'),
    }),
    onSubmit: async (values, actions) => {
      const command = {
        tripId: trip.id,
        toEmailAddress: values.email,
      };
      try {
        const {
          data: { executeCreateInvoiceDeliveryRequest: result },
        } = await createInvoiceDeliveryRequest({
          variables: { command },
        });
        if (!result?.succeeded) {
          enqueueSnackbar(result?.errors[0], {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Your invoice has been sent correctly', {
            variant: 'success',
          });
        }
      } catch (err) {
        enqueueSnackbar(`Error sending invoice ${err?.message}`, {
          variant: 'error',
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit} data-testid="send_invoice_form">
      <Grid container direction="row">
        <Grid item xs={8}>
          <Box className={classes.containerInput}>
            <InputLabel htmlFor="send_invoice_email_input">
              Send Invoice to:
            </InputLabel>
            <TextField
              error={Boolean(touched?.email && errors?.email)}
              id="send_invoice_email_input"
              helperText={touched?.email && errors?.email}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.containerButton}>
          <Button
            data-testid="send_invoice_button"
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            className={classes.sendButton}
          >
            {isSubmitting ? 'Loading...' : 'Send'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  containerInput: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    '& > label': {
      display: 'flex',
      alignItems: 'center',
      minWidth: 'fit-content',
      marginRight: theme.spacing(2),
    },
  },
  containerButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendButton: {
    color: theme.palette.textLight.light,
  },
}));

export default SendInvoicePDF;
